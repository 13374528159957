var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-layout',{attrs:{"align-content-center":"","justify-center":""}},[_c('v-flex',{staticClass:"d-flex flex-column align-content-center",staticStyle:{"max-width":"450px","margin-top":"10%"},attrs:{"md4":"","sm8":"","xs12":""}},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-img',{staticClass:"shrink mr-1 pt-3 ml-1",staticStyle:{"opacity":"0.85"},attrs:{"src":require('@/assets/logo.png'),"contain":"","width":"150"}}),_c('v-toolbar-title',{staticClass:"text-uppercase red--text"},[_c('strong',[_vm._v("FDM ")]),_c('strong',[_vm._v("Monster")])]),_c('v-toolbar-title',{staticClass:"mt-lg-6 mt-sm-5"},[_vm._v(" Register new account")]),_c('v-card-subtitle',{staticClass:"grey--text"},[_vm._v(" Welcome! Please register your guest account ")])],1),_c('v-card',{staticClass:"elevation-4 pa-4",staticStyle:{"border-radius":"10px"}},[_c('v-card-text',[_c('v-form',[_c('label',[_vm._v(" Username ")]),_c('v-text-field',{attrs:{"rules":[
                (v) => !!v || 'Username is required',
                (v) => (v?.length ?? 0) >= 3 || 'Username must be of length 3 or greater',
                (v) =>
                  !v.toLowerCase().includes('admin') || 'Username may not contain the word admin',
                (v) =>
                  !v.toLowerCase().includes('root') || 'Username may not contain the word root',
                (v) => !(v.toLowerCase() === 'demo') || 'Username may not equal the word demo',
              ],"autofocus":"","name":"login","label":"Username","prepend-icon":"person","type":"text"},model:{value:(_setup.username),callback:function ($$v) {_setup.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"id":"password","append-icon":_setup.showPassword ? 'visibility' : 'visibility_off',"type":_setup.showPassword ? 'text' : 'password',"label":"Password","name":"password","rules":[
                (v) => !!v || 'Password is required',
                (v) => (!!v && v?.length >= 8) || 'Password must be of length 8 or greater',
              ],"password":"","prepend-icon":"lock"},on:{"click:append":function($event){_setup.showPassword = !_setup.showPassword}},model:{value:(_setup.password),callback:function ($$v) {_setup.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"id":"password","append-icon":_setup.showPassword2 ? 'visibility' : 'visibility_off',"type":_setup.showPassword2 ? 'text' : 'password',"rules":[
                (v) => !!v || 'Repeated password is required',
                (v) => v === _setup.password || 'Passwords are not equal',
              ],"label":"Repeated Password","name":"password","password":"","prepend-icon":"lock"},on:{"click:append":function($event){_setup.showPassword2 = !_setup.showPassword2},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_setup.formIsDisabled || _setup.registerAccount()}},model:{value:(_setup.password2),callback:function ($$v) {_setup.password2=$$v},expression:"password2"}}),(_setup.errorMessage)?_c('v-alert',{staticClass:"mt-6",attrs:{"color":"error","dense":"","outlined":""}},[_vm._v(" "+_vm._s(_setup.errorMessage)+" ")]):_vm._e()],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"pa-4",staticStyle:{"width":"100%"},attrs:{"loading":_setup.loading,"color":"primary","large":""},on:{"click":function($event){return _setup.registerAccount()}}},[_vm._v(" Register account ")])],1),_c('v-card-actions',[_c('v-btn',{staticClass:"pa-4",staticStyle:{"width":"100%"},attrs:{"large":""},on:{"click":function($event){return _setup.gotoLogin()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("arrow_left")]),_vm._v("Back to Login ")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }